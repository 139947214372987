<template>
  <div style="width: 1200px; margin: 0 auto; padding: 40px 0">
    <a-spin size="large" :spinning="spinning">
      <a-form ref="decform" layout="vertical" :rules="rules" :model="sendData">
        <a-row type="flex" justify="space-between" :gutter="20">
          <a-col :span="12">
            <a-form-item
              label="企业"
              :rules="{
                required: true,
                message: '请选择企业',
              }"
              name="enterprise_id"
            >
              <a-select
                v-model:value="sendData.enterprise_id"
                placeholder="请选择企业"
                size="large"
                :disabled="readonly"
              >
                <a-select-option
                  v-for="(item, index) in enterprise_list"
                  :value="item.enterprise_id"
                  :key="index"
                >
                  {{ item.enterprise_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="法人代表"
              :rules="{
                required: true,
                message: '请输入法人代表',
              }"
              name="record_legal_person"
            >
              <a-input
                v-model:value="sendData.record_legal_person"
                placeholder="请输入法人代表"
                :readonly="readonly"
              ></a-input>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              label="联系人"
              :rules="{
                required: true,
                message: '请输入联系人',
              }"
              name="record_contact_name"
            >
              <a-input
                v-model:value="sendData.record_contact_name"
                placeholder="请输入联系人"
                :readonly="readonly"
              ></a-input>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              label="联系电话"
              :rules="{
                required: true,
                message: '请输入联系电话',
              }"
              name="record_contact_phone"
            >
              <a-input
                v-model:value="sendData.record_contact_phone"
                placeholder="请输入联系电话"
                :readonly="readonly"
              ></a-input>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item
              label="通讯地址"
              :rules="{
                required: true,
                message: '请输入通讯地址',
              }"
              name="record_contact_address"
            >
              <a-input
                v-model:value="sendData.record_contact_address"
                placeholder="请输入通讯地址"
                :readonly="readonly"
              ></a-input>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              label="金额（万元）"
              :rules="{
                required: true,
                message: '请输入金额（万元）',
              }"
              name="record_value"
            >
              <a-input
                v-model:value="sendData.record_value"
                placeholder="请输入金额（万元）"
                type="number"
                :readonly="readonly"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="资助项目"
              :rules="{
                required: true,
                message: '请输入资助项目',
              }"
              name="record_project"
            >
              <a-input
                v-model:value="sendData.record_project"
                placeholder="请输入资助项目"
                :readonly="readonly"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="开户行"
              :rules="{
                required: true,
                message: '请输入开户行',
              }"
              name="record_bank_name"
            >
              <a-input
                v-model:value="sendData.record_bank_name"
                placeholder="请输入开户行"
                :readonly="readonly"
              ></a-input>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item
              label="银行账号"
              :rules="{
                required: true,
                message: '请输入银行账号',
              }"
              name="record_bank_no"
            >
              <a-input
                v-model:value="sendData.record_bank_no"
                placeholder="请输入银行账号"
                :readonly="readonly"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item
              name="record_remark"
              label="资助说明"
              :rules="{ required: true, message: '请输入内容' }"
            >
              <a-textarea
                v-model:value="sendData.record_remark"
                size="large"
                :auto-size="{ minRows: 2, maxRows: 4 }"
                placeholder="输入内容......"
                :readonly="readonly"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              name="record_apply_time"
              label="申报日期"
              :rules="{ required: true, message: '请输入内容' }"
            >
              <a-date-picker
                size="large"
                v-model:value="sendData.record_apply_time"
                style="width: 100%"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                :disabled="readonly"
              />
            </a-form-item>
          </a-col>
          <a-col
            :span="24"
            v-if="sendData.record_status == 0"
            style="display: flex; justify-content: center; align-items: center"
          >
            <a-space>
              <a-button
                v-if="sendData.record_id && sendData.record_status == 0"
                block
                size="large"
                type="danger"
                class="btn2"
                @click="deleterecord"
              >
                <span>删除</span>
              </a-button>
              <a-button
                block
                size="large"
                type="primary"
                html-type="submit"
                class="btn"
                :loading="loading"
                @click="handleSubmit"
              >
                提交申报
              </a-button>
            </a-space>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </div>
</template>
<script setup>
import url from "@/utils/url.js";
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref,
} from "@vue/runtime-core";
import { useStore } from "vuex";
import {
  getDeclarationRecordData,
  setDeclarationRecordData,
  getEnterpriseList2,
  updateDeclarationRecordStatusData,
} from "@/api/main.js";
import { useRoute, useRouter } from "vue-router";
import { encode, decode } from "js-base64";
import { message, Modal } from "ant-design-vue";
const store = useStore();
let user_id = store.state.user.user_id;
let park_id = store.state.user.user_park_id;
const route = useRoute();
const router = useRouter();
const loading = ref(false);
let val = route.query;
let declaration_id;
const readonly = ref(false);
if (val?.t) {
  declaration_id = decode(val?.t);
}
let record_id = ref();
console.log(1);
const spinning = ref(false);
if (val?.record_id) {
  record_id.value = decode(val?.record_id);
}
console.log(2);
const enterprise_list = ref([]);
const sendData = ref({
  declaration_id: null,
  user_id: user_id,
  record_id: null,
  park_id: park_id,
  enterprise_id: null,
  record_legal_person: null,
  record_contact_name: null,
  record_contact_phone: null,
  record_contact_address: null,
  record_value: null,
  record_project: null,
  record_bank_name: null,
  record_bank_no: null,
  record_remark: null,
  record_apply_time: null,
  record_status: 0,
});
if (record_id.value) {
  spinning.value = true;
  let arr = Object.keys(sendData.value);
  getDeclarationRecordData({
    record_id: record_id.value,
    user_id,
    park_id,
  }).then((res) => {
    arr.map((v) => {
      res.data.declaration_record[v]
        ? (sendData.value[v] = res.data.declaration_record[v])
        : "";
    });
    spinning.value = false;
  });
}
getEnterpriseList2({
  user_id,
  park_id,
}).then((res) => {
  enterprise_list.value = res.data.enterprise;
});

const decform = ref();
const handleSubmit = () => {
  decform.value
    .validate()
    .then(() => {
      sendData.value.declaration_id = declaration_id;
      record_id.value ? (sendData.value.record_id = record_id.value) : "";
      console.log(sendData.value);
      loading.value = true;
      setDeclarationRecordData(sendData.value)
        .then((res) => {
          if (res.code == 200) {
            message.success("提交成功");
          } else {
            message.error(res.msg);
          }
          loading.value = false;
          url.replaceTo("/declaration/recordlist");
        })
        .catch(() => {
          message.error("提交失败");
          loading.value = false;
        });
    })
    .catch();
};
const deleterecord = () => {
  Modal.confirm({
    title: "提示",
    content: "确认删除？",
    centered: true,
    onOk: () => {
        spinning.value=true
      updateDeclarationRecordStatusData({
        record_id: record_id.value,
        record_status: -1,
        user_id,
      })
        .then((res) => {
          if (res.code == 200) {
            message.success("删除成功");
            url.replaceTo("/declaration/recordlist");
          } else {
            message.error(res.msg);
          }
          spinning.value=false
        })
        .catch((er) => {
          message.error("删除失败");
          spinning.value=false

        });
    },
  });
};
</script>
<style scoped lang="less">
.btn2 {
  width: 120px;
  height: 60px;
  background-color: #d8360d;
  border-radius: 30px;
  line-height: 60px;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  cursor: pointer;
  margin: 126px auto;
  position: relative;
  span {
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>